import request from "./config";
export default class UserService {
  static findAll(query = {}) {
    return request({
      url: "/users",
      method: "get",
      timeout: 10000,
      params: query,
    });
  }
  static create(data){
    return request({
      url: "/users",
      method: "post",
      timeout: 100000,
      data,
    });
  }
  static delete(data){
    return request({
      url: `/users/delete/${data.id}`,// /api
      method: "delete",
      timeout: 100000,
    });
  }
  static update(id,data){
    return request({
      url: "/users/"+id,
      method: "put",
      timeout: 100000,
      data,
    });
  }
  static findOne(query={}){
    return request({
      url: "/users",
      method: "get",
      timeout: 100000,
      params:query
    });
  }

  static listSlug(slug){
    return request({
      url: "/company/"+slug,
      method: "get",
      timeout:60000,
    });
  }
  static confirm(data) {
    return request({
      url: "/users/confirm",
      method: "post",
      data,
    });
  }
  static login(data) {
    return request({
      url: "/auth/login",
      timeout:60000,
      method: "post",
      data,
    });
  }
  static passwordRecuva(data) {
    return request({
      url: "/users/password-recuva",
      method: "post",
      timeout:60000,
      data:data,
    });
  }
  static confirmPasswordRecuva(data) {
    return request({
      url: "/users/password-confirm",
      method: "post",
      timeout:60000,
      data:data,
    });
  }
  static signupcompany(data) {
    return request({
      url: "/users/company",
      method: "post",
      timeout:60000,
      data,
    });
  }
  static signup(data) {
    return request({
      url: "/users/signup",
      method: "post",
      data,
    });
  }

  static listMessages(){
    return request({
      url: "/company/listMessages",
      method: "get",
    });
  }
 
  
}
