<template>
    <main class="J-app-body">
        <div class="J-container-bar ">
          <div class="J-container-login login J-body-image">
             <div class="J-container-main ">
              <div class="J-container-content">
                <router-view/>
              </div>
             </div>
          </div>
      </div>
    </main>
</template>
<script>
// import { useIpcRenderer } from '@vueuse/electron'
// const ipcRenderer = useIpcRenderer()

export default {
  name: 'DefaultLayout',
  props: {
    
  },
  data: ()=>({
    window:{
      isMaxi:false,
    }
  }),
  methods:{
    minus(){
      // ipcRenderer.send('windows:minus','')
    },
    restore(){
      // ipcRenderer.send('windows:restore','')
      this.window.isMaxi=true;
    },
    maxi(){
      // ipcRenderer.send('windows:maxi','')
      this.window.isMaxi=false;
    },
    close(){
      // ipcRenderer.send('windows:close','')
    }
  }
}
</script>