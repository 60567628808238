import request from "./config";
export default class PublicityService {
  static list(query = {}) {
    return request({
      url: "/product",
      method: "get",
      timeout: 10000,
      params: query,
    });
  }
  static create(data){
    return request({
      url: "/product",
      method: "post",
      timeout: 100000,
      data,
    });
  }
  static delete(data){
    return request({
      url: `/product/delete/${data.id}`,// /api
      method: "delete",
      timeout: 100000,
    });
  }
  static deleteImage(data){
    return request({
      url: `/repositories/delete/${data.id}`,// /api
      method: "delete",
      timeout: 100000,
    });
  }
  
  static update(id,data){
    return request({
      url: "/product/"+id,
      method: "put",
      timeout: 100000,
      data,
    });
  }
}
