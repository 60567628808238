import axios from "axios";
import Notiflix from 'notiflix';

const request = axios.create({
  baseURL: process.env.VUE_APP_URL || "/api/v1",
  // baseURL:"http://192.168.104.94:3000/api/v1",
});

request.defaults.timeout = 2500;
request.interceptors.request.use(
  (config) => {
    console.log("=========================================")
    Notiflix.Loading.pulse();
    var accessToken = localStorage.getItem("token");
    if (accessToken) {
      config.headers["Authorization"] = accessToken;
    }
    return config;
  },
  (error) => {
    Notiflix.Loading.remove();
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  (response) => {
    Notiflix.Loading.remove();
    if (response.status !== 200) {
      return Promise.reject(response.message);
    }
    else if(response.status==200){
      if(response.data){
        if(response.data.message) Notiflix.Notify.success(response.data.message)
      }
    }
    return response;
  },
  (error) => {
    console.log("+++++++++++++++++++++++++++++++++++++++++++")
    console.log(error)
    console.log(error.response.data.error.message)
    if(error.response){
      if(error.response.data) {
        if(error.response.data.message) Notiflix.Notify.failure(error.response.data.message)
        if(error.response.data.error) {
          if(error.response.data.error.name=="TokenExpiredError"){
            Notiflix.Notify.failure("Session Expired, Please Login Again")
            localStorage.removeItem("token")
            localStorage.removeItem("user")
            window.location.href="/"
          }
          if(error.response.data.error.message) Notiflix.Notify.failure(error.response.data.error.message)
        }
      }
      if(error.response.message) {
        if(error.response.message) Notiflix.Notify.failure(error.response.message)
      }
      
    }
    Notiflix.Loading.remove();
    return Promise.reject(error);
  }
);

export default request;
