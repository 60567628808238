import request from "./config";
export default class CotizationService {
  static list(query = {}) {
    return request({
      url: "/cotization",
      method: "get",
      timeout: 10000,
      params: query,
    });
  }
  static listLast(query = {}) {
    return request({
      url: "/cotization/last",
      method: "get",
      timeout: 10000,
      params: query,
    });
  }
  
  static searchDocument(query = {}) {
    return request({
      url: "/cotization/document-search",
      method: "get",
      timeout: 10000,
      params: query,
    });
  }
  static create(data){
    return request({
      url: "/cotization",
      method: "post",
      timeout: 100000,
      data,
    });
  }
  static changeStatus(data){
    return request({
      url: `/cotization/change-status/${data.id}`,
      method: "put",
      timeout: 100000,
      data,
    });
  }
  static delete(data){
    return request({
      url: `/cotization/delete/${data.id}`,// /api
      method: "delete",
      timeout: 100000,
    });
  }
  static update(id,data){
    return request({
      url: "/cotization/"+id,
      method: "put",
      timeout: 100000,
      data,
    });
  }
}
