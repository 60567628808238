import request from "./config";
export default class ClientService {
  static list(query = {}) {
    return request({
      url: "/client",
      method: "get",
      timeout: 10000,
      params: query,
    });
  }
  static searchDocument(query = {}) {
    return request({
      url: "/client/document-search",
      method: "get",
      timeout: 10000,
      params: query,
    });
  }
  static create(data){
    return request({
      url: "/client",
      method: "post",
      timeout: 100000,
      data,
    });
  }
  static delete(data){
    return request({
      url: `/client/delete/${data.id}`,// /api
      method: "delete",
      timeout: 100000,
    });
  }
  static update(id,data){
    return request({
      url: "/client/"+id,
      method: "put",
      timeout: 100000,
      data,
    });
  }
}
