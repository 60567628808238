import {createRouter, createWebHistory} from 'vue-router';

// import DashboardSettings from '../components/routes/DashboardSettings'
import Applayouts from '../components/layouts/app.layouts.vue'
import Dashboardlayouts from '../components/layouts/dashboard.layouts.vue'
 const routes=[
    // {
    //     path: '*',
    //     redirect: '/'
    // },
    {
        path:"/",
        name:"/",
        component: Applayouts,
        children:[
            {
                path: "/",
                name: "login",
                component:()=>import('../pages/auth/login.page')
            },
        ]
    },
    {
        path: "/",
        name: "page",
        component: Dashboardlayouts,
        meta: {
            required: true,
            roles: ["user", "superadmin", "admin","guardia"],
        },
        children:[
            {
                path: "/dashboard",
                name: "dashboard",
                component:()=>import('../pages/admin/dashboard.page.vue'),
                meta: {
                    required: true,
                    roles: ["user", "superadmin", "admin","guardia","almacen"],
                },
            },
            {
                path: "/users",
                name: "users",
                component:()=>import('../pages/admin/users/index.page.vue'),
                meta: {
                    required: true,
                    roles: ["user", "superadmin", "admin","guardia","almacen"],
                },
            },
            {
                path: "/product",
                name: "product",
                component:()=>import('../pages/admin/product/index.page.vue'),
                meta: {
                    required: true,
                    roles: ["user", "superadmin", "admin","guardia","almacen"],
                },
            },
            {
                path: "/cotization",
                name: "cotization",
                component:()=>import('../pages/admin/cotization/index.page.vue'),
                meta: {
                    required: true,
                    roles: ["user", "superadmin", "admin","guardia","almacen"],
                },
            },
            {
                path: "/client",
                name: "client",
                component:()=>import('../pages/admin/client/index.page.vue'),
                meta: {
                    required: true,
                    roles: ["user", "superadmin", "admin","guardia","almacen"],
                },
            },
            
            {
                path: "/telephones",
                name: "telephones",
                component:()=>import('../pages/admin/telephones/index.page.vue'),
                meta: {
                    required: true,
                    roles: ["user", "superadmin", "admin","guardia","almacen"],
                },
            },
            {
                path: "/category",
                name: "category",
                component:()=>import('../pages/admin/category/index.page.vue'),
                meta: {
                    required: true,
                    roles: ["user", "superadmin", "admin","guardia","almacen"],
                },
            },
        ]
    },
];

const router =createRouter(
    {
        history: createWebHistory(),
        routes
});
router.beforeEach((to, from, next) => {
    if (to.meta) {
        if(to.path=='/'){
            const token = localStorage.getItem("token") ;
            if(token){
                router.push("/dashboard");
            }
            else{
                next();
            }
        }
        else if (to.meta.required) {
            const user = JSON.parse(localStorage.getItem("user") || null);
            const token = localStorage.getItem("token") ;
            if (user) {
                if (token) {
                    
                    if (to.meta.roles) {
                        let access = false;
                        for (let index = 0; index < to.meta.roles.length; index++) {
                            const element = to.meta.roles[index];
                            if (user.rol.includes(element)) {
                                access = true;
                            }
                        }
                        if (access) {
                            next();
                        } else {
                            router.push("/");
                        }
                    }
                    next();
                } else {
                    router.push("/");
                }
            }
            else {
                router.push("/");
            }
        }
    }
    next();
});
    
export default router;