import request from "./config";
export default class TelephonesService {
  static list(query = {}) {
    return request({
      url: "telephone/list-all",
      method: "get",
      timeout: 10000,
      params: query,
    });
  }
  static update(id,data){
    return request({
      url: `/telephone/${id}`,
      method: "patch",
      timeout: 100000,
      data,
    });
  }
  static create(data){
    return request({
      url: "/telephone",
      method: "post",
      timeout: 100000,
      data,
    });
  }
  static delete(id){
    return request({
      url: `/telephone/${id}`,
      method: "delete",
      timeout: 100000,
    });
  }
  
}
